<template>
  <div id="option-window">
    <EditorHeader @close="closeOption" />
    <Spinner v-if="!isLoading" :propsOverlay="true" />
    <div
      class="static-container"
      :class="{'static-container--popup_show': getPopup.page}"
    >
      <!-- 컴포넌트 상단 -->
      <ComponentTop
        v-show="getPopup.page && getPopup.page !== 'ComponentImage'"
        @passPanel="passPanel"
      />
      <!-- 컴포넌트 옵션 -->
      <ComponentOption
        v-if="isLoading"
        v-show="!getPopup.page"
        :partnerOptions="options"
        :partnerComponentName="componentName"
        :editable="editable > 0 && !getPopup.page ? true : false"
      />
      <!-- 이미지 선택 -->
      <ComponentImage
        ref="componentImage"
        v-if="getPopup.page === 'ComponentImage'"
      />

      <!-- multi text info -->
      <ComponentMultiText
        ref="componentMultiText"
        v-if="getPopup.page === 'ComponentMultiText'"
      />
      <!-- //multi text info -->

      <!-- multi text url info -->
      <ComponentMultiTextURL
        ref="componentMultiTextURL"
        v-if="getPopup.page === 'ComponentMultiTextURL'"
      />
      <!-- //multi text url info -->

      <!-- multi image info -->
      <ComponentMultiImage
        ref="componentMultiImage"
        v-if="getPopup.page === 'ComponentMultiImage'"
      />
      <!-- //multi image info -->

      <!-- multi image url info -->
      <ComponentMultiImageURL
        ref="componentMultiImageURL"
        v-if="getPopup.page === 'ComponentMultiImageURL'"
      />

      <!-- multi text image url info -->
      <ComponentMultiTextImageURL
        ref="componentMultiTextImageURL"
        v-if="getPopup.page === 'ComponentMultiTextImageURL'"
      />
      <div class="fixed-bottom" v-show="editable > 0 && !getPopup.page">
        <button
          type="submit"
          class="fixed-bottom--save"
          @click="beforeUpdateOptionProperty"
        >
          저장하기
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import VueMasonry from "vue-masonry-css";
import Vue from "vue";

const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleEditor = createNamespacedHelpers("ModuleEditor");
const ModuleComponent = createNamespacedHelpers("ModuleComponent");
const ModuleComponentOption = createNamespacedHelpers("ModuleComponentOption");

Vue.use(VueMasonry);
export default {
  data() {
    return {
      isLoading: false,
      editable: 1,
      desktop: {},
      mobile: {},
      options: {},
      componentName: ""
    };
  },
  created() {
    this.setLinkedComponentId(this.$route.query.componentId);
    this.setPanel("ComponentOption");
    this.componentRead();
    this.optionList();
    this.editable = this.$route.query.editable;
  },
  methods: {
    closeOption() {
      window.parent.postMessage(
        {
          action: "close"
        },
        process.env.VUE_APP_PARTNER_CENTER
      );
    },
    // 컴포넌트 상세 조회
    componentRead() {
      let payload = {};
      payload["componentId"] = this.$route.query.componentId;

      this.actComponentRead(payload).then(response => {
        switch (response.status) {
          case 200:
            this.componentName = response.data.name;
            break;
        }
      });
    },
    // 옵션 목록 조회
    optionList() {
      let payload = {};
      payload["componentId"] = this.$route.query.componentId;
      payload["versionId"] = this.$route.query.versionId;
      payload["withOptionProperties"] = 1;
      payload["sortBy"] = "+sort, +id";

      this.actPartnerComponentOptionList(payload).then(response => {
        // let mobileOpt = [];
        // let desktopOpt = [];
        let arr = [];

        switch (response.status) {
          case 200:
            this.isLoading = true;
            response.data.forEach(item => {
              arr.push({
                componentOption: item,
                componentOptionId: item.id,
                visible: true,
                value: {},
                modified: {
                  properties: []
                } //업데이트할 객체
              });
              // if (item.displayOnPc) {
              //   desktopOpt.push({
              //     componentOption: item,
              //     componentOptionId: item.id,
              //     visible: true,
              //     value: {},
              //     modified: {
              //       properties: []
              //     } //업데이트할 객체
              //   });
              // }
              // if (item.displayOnMobile) {
              //   mobileOpt.push({
              //     componentOption: item,
              //     componentOptionId: item.id,
              //     visible: true,
              //     value: {},
              //     modified: {
              //       properties: []
              //     } //업데이트할 객체
              //   });
              // }
            });
            // this.desktop = desktopOpt;
            // this.mobile = mobileOpt;
            this.options = arr;
            break;
        }
      });
    },
    // 옵션 프로퍼티 기본값 수정 전처리
    beforeUpdateOptionProperty() {
      // let target =
      //   this.getDeviceType === "desktop" ? this.desktop : this.mobile;
      let options = [];

      this.options.forEach(item => {
        options.push(item.modified);
      });

      this.updateOptionProperty(options);
    },
    // 옵션 프로퍼티 기본값 수정
    updateOptionProperty(options) {
      let payload = {};
      payload["componentId"] = this.$route.query.componentId;
      payload["versionId"] = this.$route.query.versionId;
      payload["options"] = options;

      this.actPartnerPropertyUpdate(payload).then(response => {
        switch (response.status) {
          case 201:
            this.setAlert({
              isVisible: true,
              message: `정상 처리 되었습니다.`
            });
            break;

          default:
            this.setAlert({
              isVisible: true,
              message: response.data.errors[0].userMsg
            });
            break;
        }
      });
    },
    // 이전 페이지로 이동
    passPanel(page) {
      if (page === "image") {
        this.$refs.componentImage.goPrev();
      } else if (page === "icon") {
        this.$refs.componentIcon.goPrev();
      } else if (page === "multiText") {
        this.$refs.componentMultiText.goPrev();
      } else if (page === "multiTextURL") {
        this.$refs.componentMultiTextURL.goPrev();
      } else if (page === "multiImage") {
        this.$refs.componentMultiImage.goPrev();
      } else if (page === "multiImageURL") {
        this.$refs.componentMultiImageURL.goPrev();
      } else if (page === "multiTextImageURL") {
        this.$refs.componentMultiTextImageURL.goPrev();
      } else if (page === "productAdd") {
        this.$refs.componentProductAdd.goPrev();
      } else if (page === "productList") {
        this.$refs.componentProductList.goPrev();
      } else {
        this.setPanel("ComponentOption");
      }
    },
    ...ModuleEditor.mapMutations(["setLinkedComponentId", "setPanel"]),
    ...ModuleComponentOption.mapActions([
      "actPartnerComponentOptionList",
      "actPartnerPropertyUpdate"
    ]),
    ...ModuleComponent.mapActions(["actComponentRead"]),
    ...ModuleAlert.mapMutations(["setAlert"]),
    ...ModuleEditor.mapMutations(["setPopup"])
  },
  computed: {
    ...ModuleEditor.mapGetters(["getDeviceType", "getPopup", "getPanel"])
  },
  watch: {},
  components: {
    EditorHeader: () => import("@/components/editor/Header"),
    ComponentTop: () => import("@/components/editor/ComponentTop"),
    ComponentOption: () => import("@/components/editor/ComponentOption"),
    ComponentImage: () => import("@/components/editor/ComponentImage"),
    ComponentMultiText: () => import("@/components/editor/ComponentMultiText"),
    ComponentMultiTextURL: () =>
      import("@/components/editor/ComponentMultiTextURL"),
    ComponentMultiImage: () =>
      import("@/components/editor/ComponentMultiImage"),
    ComponentMultiImageURL: () =>
      import("@/components/editor/ComponentMultiImageURL"),
    ComponentMultiTextImageURL: () =>
      import("@/components/editor/ComponentMultiTextImageURL")
  }
};
</script>

<style lang="scss">
body {
  overscroll-behavior: none;
  overflow: hidden;
}
#app {
  height: 100vh;
  height: -webkit-stretch;
  height: stretch;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.static-container {
  position: fixed;
  top: 57px;
  width: 412px;
  //height: 100%;
  //height: calc(100% - 57px);
  //overflow-y: auto;
  &--popup_show {
    height: 100%;
    //height: calc(100% - 113px); //113px: top + fixed-bottom-height
  }
}
/* from editor css*/
.DAV-workshop {
  z-index: 10;
  position: fixed;
  top: auto !important;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 16px 16px 0px 0px;
  background-color: #f7f9fc;
}
.DAV-workshop__space {
  z-index: 11;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  background-color: #f7f9fc;
}
.DAV-workshop__trunk {
  z-index: 1;
  position: absolute;
  //top: 56px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.DAV-blockClass {
  z-index: 1;
  position: absolute;
  top: 56px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  background-color: #f7f9fc;
}
.DAV-titleBar {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px 0 12px;
  border-bottom: 1px solid #f1f1f1;

  h4 {
    color: #606060;
    font-size: 16px;
    font-weight: 500;
  }
  &__help {
    display: flex;
    width: 24px;
    height: 24px;
    margin-left: 2px;
    background-image: url($path + "ico_help.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
  }
  &__toggle {
    position: relative;
    width: 48px;
    height: 24px;
    margin-left: 8px;
    background: #dcdcdc;
    border-radius: 24px;
    text-indent: -9999px;

    span {
      position: absolute;
      top: 2px;
      left: 2px;
      background: #ffffff;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      width: 20px;
      height: 20px;
    }
    &--active {
      background-color: #54c7a2;

      span {
        left: 26px;
      }
      span::before {
        position: absolute;
        top: 0;
        left: -19px;
        width: 12px;
        height: 100%;
        content: "";
        background-image: url($path + "bul_selected.svg");
        background-repeat: no-repeat;
        background-position: 0 50%;
        background-size: 12px auto;
      }
    }
  }
  &__device {
    width: 24px;
    height: 24px;
    margin-left: auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;

    &--mobile {
      background-image: url($path + "bg_device_mobile.svg");
    }
    &--pc {
      background-image: url($path + "bg_device_pc.svg");
    }
    &--union {
      background-image: url($path + "bg_device_union.svg");
    }
  }
}
.titleGuide {
  z-index: 2;
  display: block;
  position: absolute;
  top: 45px;
  right: 0;
  left: 0;
  padding: 12px 32px 12px 12px;
  background-color: #ffffff;
  border: 1px solid #d8dae5;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  &--close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 18px;
    height: 18px;
    background-image: url($path + "ico_modal_close.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
  }
  &__head {
    color: #484948;
    font-size: 14px;
  }
  &__list li {
    margin-top: 6px;
    margin-left: 16px;
    color: #484948;
    font-size: 12px;
    list-style-type: disc;
  }
  &__text {
    color: #484948;
    font-size: 12px;

    p {
      padding-left: 8px;
      text-indent: -8px;
      letter-spacing: -0.5px;
    }
    button {
      color: #3366ff;
    }
  }
}
.frmBlock__layout {
  position: relative;
  display: flex;
  margin-top: 16px;

  &--grow {
    .frmBlock__textField {
      flex: auto;
      width: 100%;
    }
    .frmBlock__dropdownField {
      margin-left: 16px;
      flex: none;
      width: 128px;
    }
    .frmBlock__selectField {
      margin-left: 16px;
      flex: none;
      width: 128px;
    }
  }
}
.frmBlock__selectField {
  height: 48px;
  padding: 0 16px;
  border: 1px solid #d8dae5;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  background-image: url($path + "bul_arr_down.svg");
  background-repeat: no-repeat;
  background-position: right 16px center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.frmBlock__display {
  display: flex;
  margin: 16px -8px 0;

  button {
    position: relative;
    flex: 1;
    height: 88px;
    margin: 0 8px;
    border: 1px solid #d8dae5;
    box-sizing: border-box;
    border-radius: 8px;
  }
  span {
    display: block;
    margin-top: 60px;
    color: #8d8d8d;
    font-size: 14px;
    text-align: center;
  }
  span::before {
    display: none;
    content: "selected";
    position: absolute;
    top: 8px;
    right: 8px;
    width: 16px;
    height: 16px;
    background-image: url($path + "bg_selected.svg");
    background-repeat: no-repeat;
    background-position: 0 0;
    text-indent: -9999px;
  }
}
.frmBlock__title {
  margin: 0 0 -8px;
  font-size: 14px;
  font-weight: 500;
  color: #484948;
  position: relative;

  &--last {
    margin-top: 22px;
  }
}
.deviceDisplay {
  &__union {
    background-image: url($path + "bul_device_union.svg");
    background-repeat: no-repeat;
    background-position: 50% 18px;

    &.deviceDisplay--active {
      background-image: url($path + "bul_device_union_on.svg");
    }
  }
  &__mobile {
    background-image: url($path + "bul_device_mobile.svg");
    background-repeat: no-repeat;
    background-position: 50% 18px;

    &.deviceDisplay--active {
      background-image: url($path + "bul_device_mobile_on.svg");
    }
  }
  &__pc {
    background-image: url($path + "bul_device_pc.svg");
    background-repeat: no-repeat;
    background-position: 50% 18px;

    &.deviceDisplay--active {
      background-image: url($path + "bul_device_pc_on.svg");
    }
  }
  &--active {
    background-color: #d2f8ec;
    border: 1px solid #54c7a2 !important;

    span {
      color: #54c7a2;
    }
    span::before {
      display: block;
    }
  }
}
.areaPosition {
  &__header {
    background-image: url($path + "ico_position_header.svg");
    background-repeat: no-repeat;
    background-position: 50% 14px;

    &.areaPosition--active {
      background-image: url($path + "ico_position_header_on.svg");
    }
  }
  &__content {
    background-image: url($path + "ico_position_content.svg");
    background-repeat: no-repeat;
    background-position: 50% 14px;

    &.areaPosition--active {
      background-image: url($path + "ico_position_content_on.svg");
    }
  }
  &__footer {
    background-image: url($path + "ico_position_footer.svg");
    background-repeat: no-repeat;
    background-position: 50% 14px;

    &.areaPosition--active {
      background-image: url($path + "ico_position_footer_on.svg");
    }
  }
  &--active {
    background-color: #d2f8ec;
    border: 1px solid #54c7a2 !important;

    span {
      color: #54c7a2;
    }
    span::before {
      display: block;
    }
  }
}
/* transition */
.fade {
  &-enter-active,
  &-leave-active {
    transition: 0.5s ease;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
.slide {
  &-enter-active,
  &-leave-active {
    transition: 0.5s ease;
  }
  &-enter,
  &-leave-to {
    transform: translate(100%, 0);
  }
}
.slideup {
  &-enter-active {
    transition: 0.3s ease-in;
  }
  &-leave-active {
    transition: 0.3s cubic-bezier(0, 1, 0.5, 1);
  }
  &-enter-to,
  &-leave {
    overflow: hidden;
    max-height: 300px;
  }
  &-enter,
  &-leave-to {
    overflow: hidden;
    max-height: 0;
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .resizable-container {
    width: 421px;
    top: 0;
    left: auto;
    right: 0;
  }
  .DAV-workshop {
    width: 421px;
    height: auto !important;
    top: 0 !important;
    left: auto;
    right: 0;
  }
  .DAV-workshop__space {
    z-index: 10;
    top: 56px;
    left: auto;
    width: 421px;
  }
  .DAV-workshop__trunk {
    left: auto;
    width: 421px;
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .DAV-workshop__trunk {
    //overflow-y: auto;
  }
  .DAV-workshop__mobile {
    bottom: 0px !important;
    top: auto !important;
  }
  .DAV-blockClass {
    top: 0;
  }
}
.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  &--save {
    background: #1989fa;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    width: 100%;
    height: 56px;
  }
}
</style>
