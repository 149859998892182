var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "option-window"
    }
  }, [_c('EditorHeader', {
    on: {
      "close": _vm.closeOption
    }
  }), !_vm.isLoading ? _c('Spinner', {
    attrs: {
      "propsOverlay": true
    }
  }) : _vm._e(), _c('div', {
    staticClass: "static-container",
    class: {
      'static-container--popup_show': _vm.getPopup.page
    }
  }, [_c('ComponentTop', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getPopup.page && _vm.getPopup.page !== 'ComponentImage',
      expression: "getPopup.page && getPopup.page !== 'ComponentImage'"
    }],
    on: {
      "passPanel": _vm.passPanel
    }
  }), _vm.isLoading ? _c('ComponentOption', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.getPopup.page,
      expression: "!getPopup.page"
    }],
    attrs: {
      "partnerOptions": _vm.options,
      "partnerComponentName": _vm.componentName,
      "editable": _vm.editable > 0 && !_vm.getPopup.page ? true : false
    }
  }) : _vm._e(), _vm.getPopup.page === 'ComponentImage' ? _c('ComponentImage', {
    ref: "componentImage"
  }) : _vm._e(), _vm.getPopup.page === 'ComponentMultiText' ? _c('ComponentMultiText', {
    ref: "componentMultiText"
  }) : _vm._e(), _vm.getPopup.page === 'ComponentMultiTextURL' ? _c('ComponentMultiTextURL', {
    ref: "componentMultiTextURL"
  }) : _vm._e(), _vm.getPopup.page === 'ComponentMultiImage' ? _c('ComponentMultiImage', {
    ref: "componentMultiImage"
  }) : _vm._e(), _vm.getPopup.page === 'ComponentMultiImageURL' ? _c('ComponentMultiImageURL', {
    ref: "componentMultiImageURL"
  }) : _vm._e(), _vm.getPopup.page === 'ComponentMultiTextImageURL' ? _c('ComponentMultiTextImageURL', {
    ref: "componentMultiTextImageURL"
  }) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.editable > 0 && !_vm.getPopup.page,
      expression: "editable > 0 && !getPopup.page"
    }],
    staticClass: "fixed-bottom"
  }, [_c('button', {
    staticClass: "fixed-bottom--save",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.beforeUpdateOptionProperty
    }
  }, [_vm._v(" 저장하기 ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }